<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">Informácie o žiadanke</h4>
          </div>
          <div class="card-block">
            <div class="mrg-top-20 row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-5">
                    <b>Schvaľovateľ</b>
                  </div>
                  <div class="col-md-7">
                    {{ invoice.testText }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-5">
                    <b>Názov</b>
                  </div>
                  <div class="col-md-7">
                    {{ invoice.testText }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-5">
                    <b>Typ položky</b>
                  </div>
                  <div class="col-md-7">
                    {{ invoice.testText }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-5">
                    <b>Kategória (CPV)</b>
                  </div>
                  <div class="col-md-7">
                    {{ invoice.testText }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-5">
                    <b>Celková predp. hodnota (PHZ) v EUR</b>
                  </div>
                  <div class="col-md-7">
                    {{ invoice.testText }}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-5">
                    <b>Opis predmetu zákazky</b>
                  </div>
                  <div class="col-md-7">
                    {{ invoice.testText }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-5">
                    <b>Odôvodnenie potreby</b>
                  </div>
                  <div class="col-md-7">
                    {{ invoice.testText }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-5">
                    <b>Dátum dodania</b>
                  </div>
                  <div class="col-md-7">
                    {{ invoice.testText }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-5">
                    <b>Návrh uchádzačov na oslovenie</b>
                  </div>
                  <div class="col-md-7">
                    {{ invoice.testText }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-5">
                    <b>Poznámka</b>
                  </div>
                  <div class="col-md-7">
                    {{ invoice.testText }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <items-list-widget />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">Prílohy kritérií</h4>
            <div class="actions">
              <button
                v-if="invoice.attachments2 && invoice.attachments2.length > 0"
                class="btn btn-tenderia-grey-blue"
                @click.prevent="downloadAttachments('ATTACHMENTS2')"
              >
                <i class="icon-download"></i>Stiahnuť všetky prílohy
              </button>
            </div>
          </div>
          <v-table
            :items="invoice.attachments2"
            :columns="attachmentsColumns"
            item-key="id"
            class="light"
          >
            <template v-slot:cell(name)="{ item }">
              <a
                :href="$globals.backendHost + attachment.link + '?token=' + authToken"
                target="_blank"
              >
                {{ item.name }}
              </a>
            </template>
            <template v-slot:cell(type)="{ item }">
              {{ item.mime | formatFileType }}
            </template>
          </v-table>
        </div>
      </div>
    </div>
    <admin-logs />
  </div>
</template>

<script>
import AdminLogs from '@/components/invoices/components/AdminLogs.vue'
import VTable from '@/components/ui/Table.vue'

export default {
  name: 'InvoiceOverview',
  inject: ['invoice'],
  components: { AdminLogs, VTable }
}
</script>
