<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">Informácie o žiadanke</h4>
          </div>
          <div class="card-block">
            <div class="list-table">
              <div class="col">
                <div class="row">
                  <div class="col-md-5">
                    <b>Názov</b>
                  </div>
                  <div class="col-md-7">
                    {{ request.name }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-5">
                    <b>Stav</b>
                  </div>
                  <div class="col-md-7">
                    <request-state display="text" :state="request.state" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-5">
                    <b>Rozpočet</b>
                  </div>
                  <div class="col-md-7">
                    <span v-for="budget in request.budgets" :key="budget.id">
                      {{ budget.name }}
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-5">
                    <b>Schvaľovatelia</b>
                  </div>
                  <div class="col-md-7 column">
                    <span v-for="approver in request.approvers" :key="approver.id">
                      {{ approver.firstname }} {{ approver.lastname }} ({{ approver.email }}),
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-5">
                    <b>Celková predp. hodnota (PHZ) bez DPH</b>
                  </div>
                  <div class="col-md-7">
                    {{ $format.currency(request.value) }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-5">
                    <b>Celková predp. hodnota (PHZ) s DPH</b>
                  </div>
                  <div class="col-md-7">
                    {{ $format.currency(request.valueWithVat) }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-5">
                    <b>Typ položky</b>
                  </div>
                  <div class="col-md-7">
                    {{ request.subjectType | formatOrderSubject }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-5">
                    <b>Kategória (CPV)</b>
                  </div>
                  <div class="col-md-7">
                    {{ request.cpvCode }}
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="row">
                  <div class="col-md-5">
                    <b>Opis predmetu zákazky</b>
                  </div>
                  <div class="col-md-7">
                    {{ request.description }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-5">
                    <b>Odôvodnenie potreby</b>
                  </div>
                  <div class="col-md-7">
                    {{ request.justification }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-5">
                    <b>Dátum dodania</b>
                  </div>
                  <div class="col-md-7">
                    {{ request.deliveryDate | formatDate }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-5">
                    <b>Poznámka</b>
                  </div>
                  <div class="col-md-7">
                    {{ request.note }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <request-suppliers-list :editable="false" :request="request" />
    <items-list-widget :editable="false" :request="request" />
    <request-approvers-list v-if="loaded && request.state !== 'planned'" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">Prílohy</h4>
            <div class="actions">
              <button
                v-if="request.attachments && request.attachments.length > 0"
                class="btn btn-tenderia-grey-blue"
              >
                <i class="icon-download"></i>Stiahnuť všetky prílohy
              </button>
            </div>
          </div>
          <v-table
            :items="request.attachments"
            :columns="attachmentsColumns"
            item-key="id"
            class="light"
          >
            <template v-slot:cell(name)="{ item }">
              <a
                :href="$globals.backendHost + item.link + '?token=' + $globals.authToken"
                target="_blank"
              >
                {{ item.name }}
              </a>
            </template>
            <template v-slot:cell(mime)="{ item }">
              {{ item.mime | formatFileType }}
            </template>
          </v-table>
        </div>
      </div>
    </div>
    <!-- <admin-logs /> -->
  </div>
</template>

<script>
import ItemsListWidget from '@/components/requests/components/ItemsListWidget.vue'
import VTable from '@/components/ui/Table.vue'
import RequestState from '@/components/requests/components/RequestState.vue'
import RequestApproversList from '@/components/requests/components/RequestApproversList.vue'
import RequestSuppliersList from '../components/RequestSuppliersList.vue'

function initialState () {
  return {
    approvalRound: 0,
    name: '',
    description: '',
    value: 0,
    valueWithVat: 0,
    approvers: null,
    budgets: null,
    subjectType: '',
    cpvCode: '',
    justification: '',
    deliveryDate: null,
    note: '',
    applicantInfo: '',
    state: '',
    items: null,
    suppliers: null,
    approvalStances: null,
    id: ''
  }
}

export default {
  name: 'RequestEmailOverview',
  components: { ItemsListWidget, VTable, RequestState, RequestApproversList, RequestSuppliersList },
  inject: ['eventBus'],
  data () {
    return {
      request: initialState(),
      loaded: false
    }
  },
  provide () {
    return {
      request: this.request
    }
  },
  computed: {
    attachmentsColumns () {
      return [
        {
          key: 'name',
          label: 'Názov'
        },
        {
          key: 'mime',
          label: 'Typ'
        }
      ]
    }
  },
  methods: {
    logout () {
      this.$http.post('/auth/logout')
      this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$globals.authToken
    },
    async loadRequest () {
      this.loaded = false
      this.ShowLoading()
      try {
        const { data: { request } } = await this.$http.post('/request/get', { request: { id: this.$route.params.requestId } })
        Object.assign(this.request, request)
        this.loaded = true
        this.eventBus.$emit('request-data', this.request)
        this.CloseLoading()
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ text: 'Nepodarilo sa načítať informácie o žiadanke.' }, error?.response?.data.error)
      }
    },
    async downloadAttachments () {
      this.$emit('epm:new', { title: 'Pripravujem dokumenty na stiahnutie', type: 'default' })
      var data = {
        request: {
          id: this.request.id
        }
      }
      try {
        let { data: { downloadToken } } = await this.$http.post('/file/generatezip', data)
        this.$emit('epm:close')
        this.YesNoAlert({
          title: 'Prílohy sú pripravené na stiahnutie',
          type: 'info',
          cancelButtonText: 'Zrušiť',
          confirmButtonText: 'Stiahnuť'
        }).then((result) => {
          if (result.value) {
            window.open(`${this.$globals.backendUrl}/file/zip?download=${downloadToken}&token=${this.$globals.authToken}`)
          }
        })
      } catch (error) {
        this.ErrorAlert({ text: 'Pri sťahovaní nastala chyba' }, error?.response?.data.error)
        this.$emit('epm:close')
      }
    }
  },
  mounted () {
    window.addEventListener('beforeunload', this.logout)
    if (this.$route.params.requestId && this.$route.query.token) {
      this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$route.query.token
      this.loadRequest()
    }
    this.eventBus.$on('load-request', () => {
      this.loadRequest()
    })
  },
  destroyed () {
    this.eventBus.$off('load-request')
    this.logout()
  }
}
</script>
