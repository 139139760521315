<template>
  <div>
    <div class="card">
      <div class="card-heading">
        <h4 class="card-title ">Záznam úkonov</h4>
        <div class="actions">
          <button v-if="logs.total" class="btn btn-tenderia-grey-blue" href="" @click.prevent="exportSheet('csv')">
            <template v-if="!csvLoading">
              <i class="icon-download"></i>Exportovať do CSV
            </template>
            <div v-else class="spinner-border spinner-border-sm text-light" role="status"></div>
          </button>
          <button v-if="logs.total" class="btn btn-success" href="" @click.prevent="exportSheet('xlsx')">
            <template v-if="!xlsxLoading">
              <i class="icon-excel"></i>Exportovať do excelu
            </template>
            <div v-else class="spinner-border spinner-border-sm text-light" role="status"></div>
          </button>
        </div>
      </div>
      <div class="card-block">
        <div class="row align-items-baseline no-print">
          <div class="col-md-3 form-group">
            <label class="text-bold text-dark">Kategória:</label>
            <category-select v-model="category"></category-select>
          </div>
          <div class="col-md-3 form-group">
            <label class="text-bold text-dark">Dátum od:</label>
            <date-input
              name="date-from"
              v-model="from"
              :min-date="dateNotBefore"
            />
          </div>
          <div class="col-md-3 form-group">
            <label class="text-bold text-dark">Dátum do:</label>
            <date-input
              name="date-to"
              v-model="to"
              :min-date="dateNotBefore"
            />
          </div>
        </div>
      </div>
      <v-table
        :items="logs.protocols"
        :columns="logsColumns"
        :sort.sync="tableSort"
        item-key="id"
        class="light"
      >
        <template v-slot:cell(category)="{ item }">
          <a href="" @click.prevent="openDetail(item)">{{ item.category | formatLogsCategory }}</a>
        </template>
        <template v-slot:cell(action)="{ item }">
          {{ item.action | formatLogsActions(item) }}
        </template>
        <template v-slot:cell(createdAt)="{ item }">
          {{ item.createdAt | formatDateTime }}
        </template>
        <template v-slot:cell(userName)="{ item }">
          {{ item.user | formatUserName }}
        </template>
        <template v-slot:cell(result)="{ item }">
          {{ item.status | formatResult }}
        </template>
      </v-table>
      <div class="card-footer">
        <v-pagination
          v-if="logs.total"
          :page="page"
          :per-page="perPage"
          :per-page-options="pageSizeOptions"
          :total-elements="logs.total"
        />
      </div>
    </div>
    <order-settings-log-detail-switch></order-settings-log-detail-switch>
  </div>
</template>

<script>
import XLSX from 'xlsx'
import CategorySelect from '@/components/forms/CategorySelect.vue'
import OrderSettingsLogDetailSwitch from '@/components/order/common/components/OrderSettingsLogs/OrderSettingsLogDetailSwitch.vue'
import VTable from '@/components/ui/Table.vue'
import VPagination from '@/components/ui/Pagination.vue'
import DateInput from '@/components/forms/DateInput.vue'

export default {
  name: 'AdminLogs',
  components: { CategorySelect, OrderSettingsLogDetailSwitch, VTable, VPagination, DateInput },
  inject: ['eventBus', 'order'],
  data () {
    return {
      logs: {},
      dateNotBefore: process.env.VUE_APP_ENBL_ORDER_LOGS_FROM,
      from: '',
      to: '',
      category: '',
      tableSort: {
        sortBy: 'createdAt',
        sort: 'desc'
      },
      csvLoading: false,
      xlsxLoading: false
    }
  },
  computed: {
    pageSizeOptions () {
      return [5, 10, 25, 50]
    },
    page () {
      return parseInt(this.$route.query.page) || 1
    },
    perPage () {
      const perPage = parseInt(this.$route.query.size)
      const valid = perPage && this.pageSizeOptions.includes(perPage)
      return valid ? perPage : 5
    },
    logsColumns () {
      return [
        {
          key: 'category',
          label: 'Kategória',
          sortable: true
        },
        {
          key: 'action',
          label: 'Názov',
          sortable: true
        },
        {
          key: 'createdAt',
          label: 'Dátum',
          sortable: true
        },
        {
          key: 'userName',
          label: 'Používateľ',
          sortable: true
        },
        {
          key: 'ip',
          label: 'IP Adresa',
          sortable: true
        },
        {
          key: 'result',
          label: 'Výsledok',
          sortable: true
        }
      ]
    },
    fetchParams () {
      return {
        limit: this.perPage,
        page: this.page,
        offset: this.perPage * this.page - this.perPage,
        orderId: this.order.id,
        category: this.category,
        from: this.isoFrom,
        to: this.isoTo,
        sort: this.$format.sortString(this.tableSort)

      }
    }
  },
  methods: {
    async fetchLogs () {
      const { data } = await this.$http.post('/protocol/catalog', this.fetchParams)
      this.logs = data
    },
    async exportSheet (file) {
      let vm = this
      let contract = {
        orderId: this.order.id,
        sort: '-createdAt',
        limit: this.logs.total
      }
      if (file === 'csv') {
        this.csvLoading = true
      } else {
        this.xlsxLoading = true
      }
      let response = await this.$http.post('/protocol/catalog', contract)
      let logs = XLSX.utils.json_to_sheet(response.data.protocols.map((protocol) => {
        return {
          category: vm.$options.filters.formatLogsCategory(protocol.category),
          action: vm.$options.filters.formatLogsActions(protocol.action, protocol),
          createdAt: vm.$options.filters.formatDateTime(protocol.createdAt),
          userId: vm.$options.filters.formatUserName(protocol.user),
          ip: protocol.ip,
          result: vm.$options.filters.formatResult(protocol.status)
        }
      }))
      delete logs['A1'].w
      logs['A1'].v = 'Kategória'
      delete logs['B1'].w
      logs['B1'].v = 'Názov'
      delete logs['C1'].w
      logs['C1'].v = 'Dátum'
      delete logs['D1'].w
      logs['D1'].v = 'Používateľ'
      delete logs['E1'].w
      logs['E1'].v = 'IP Adresa'
      delete logs['F1'].w
      logs['F1'].v = 'Výsledok'
      logs['!cols'] = [
        { wpx: 150 },
        { wpx: 200 },
        { wpx: 150 },
        { wpx: 150 },
        { wpx: 150 }
      ]
      let document = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(document, logs, 'Zoznam logov')
      if (file === 'csv') {
        XLSX.writeFile(document, 'logy.csv', { bookType: 'csv' })
        this.csvLoading = false
      } else {
        XLSX.writeFile(document, 'logy.xlsx')
        this.xlsxLoading = false
      }
    },
    openDetail (row) {
      this.eventBus.$emit('open-log-detail', row)
    }
  },
  filters: {
    formatUserName (user) {
      return `${user.title1} ${user.firstname} ${user.lastname} ${user.title2}`.trim()
    },
    formatStates (state) {
      let states = {
        active: 'Spustenie zákazky',
        finished: 'Ukončenie zákazky',
        cancelled: 'Zrušenie zákazky',
        deleted: 'Zmazanie zákazky'
      }
      return states[state]
    },
    formatResult (status) {
      return status === '200' ? 'Úspech' : 'Chyba'
    }
  },
  watch: {
    fetchParams () {
      this.fetchLogs()
    }
  },
  mounted () {
    this.fetchLogs()
  }
}
</script>
