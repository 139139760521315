<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card" id="order-settings-general">
          <div class="card-heading">
            <h4 class="card-title">Základné údaje</h4>
          </div>
          <div class="card-block">
            <div class="row mt-3">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="contract-name" class="control-label text-dark text-bold">Názov zákazky <small>*</small></label>
                  <div>
                    <input id="contract-name" type="text" v-model="order.name" class="form-control" required="required" disabled="disabled" aria-required="true">
                  </div>
                </div>

                <div class="form-group">
                  <label for="estimated-price" class="control-label">Predpokladaná hodnota v &euro; bez DPH <small>*</small></label>
                  <div>
                    <p><vue-numeric class="form-control" currency="€" separator="space" decimal-separator="," :precision="2" :empty-value="0" :disabled="order.divided || !order._canEdit" v-model="order.estimatedPrice"></vue-numeric></p>
                    <label v-if="form.estimatedPrice.error" class="error">{{ form.estimatedPrice.errorMessage }}</label>
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label">Obstarávanie sa týka projektu a/alebo programu financovaného z fondov Európskej únie <small>*</small></label>
                  <div>
                    <div class="radio radio-inline">
                      <input id="funding-by-eu-yes" type="radio" v-model="order.fundingByEu" value="yes" :disabled="!order._canEdit" /><label for="funding-by-eu-yes">Áno</label>
                    </div>
                    <div class="radio radio-inline">
                      <input id="funding-by-eu-no" type="radio" v-model="order.fundingByEu" value="no" :disabled="!order._canEdit" /><label for="funding-by-eu-no">Nie</label>
                    </div>
                    <label v-if="form.fundingByEu.error" class="error">{{ form.fundingByEu.errorMessage }}</label>
                  </div>
                </div>

                <div class="form-group" v-if="this.order.type !== 'nh'">
                  <label for="announcement-url" class="control-label">URL v tenderia</label>
                  <div class="input-group">
                    <input id="announcement-url" type="text" v-model="order.announcementUrl" class="form-control" readonly>
                    <button class="input-group-addon copy" @click="copyToClipboard(order.announcementUrl)">
                      <i class="ei-copy font-size-18"></i>
                    </button>
                  </div>
                </div>

                <div class="form-group">
                  <label for="journal-jnnouncement-date" class="control-label">Dátum vyhlásenia súťaže</label>
                  <div>
                    <date-input name="journal-jnnouncement-date" v-model="order.journalAnnouncementDate" :value="order.journalAnnouncementDate"  :disabled="!order._canEdit"></date-input>
                    <label for="journal-jnnouncement-date" v-if="form.journalAnnouncementDate.error" class="error">{{ form.journalAnnouncementDate.errorMessage }}</label>
                  </div>
                </div>

                <div class="form-group" v-if="this.order.type !== 'nh'">
                  <label for="journal" class="control-label">Číslo vestníka</label>
                  <div>
                    <input id="journal" type="text" v-model="order.journal" class="form-control" :disabled="!order._canEdit">
                    <label for="journal" v-if="form.journal.error" class="error">{{ form.journal.errorMessage }}</label>
                  </div>
                </div>

                <div class="form-group" v-if="this.order.type !== 'nh'">
                  <label for="announcement" class="control-label">Číslo oznámenia/výzvy</label>
                  <div>
                    <input id="announcement" type="text" v-model="order.announcement" class="form-control" :disabled="!order._canEdit">
                    <label for="announcement" v-if="form.announcement.error" class="error">{{ form.announcement.errorMessage }}</label>
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label">Druh zákazky <small>*</small></label>
                  <div>
                    <div class="radio radio-inline">
                      <input id="subject-type-goods" type="radio" name="order-subject-type" v-model="order.subjectType" value="goods" :disabled="!order._canEdit" /> <label for="subject-type-goods">Tovary</label>
                    </div>
                    <div class="radio radio-inline">
                      <input id="subject-type-services" type="radio" name="order-subject-type" v-model="order.subjectType" value="services" :disabled="!order._canEdit" /> <label for="subject-type-services">Služby</label>
                    </div>
                    <div class="radio radio-inline">
                      <input id="subject-type-work" type="radio" name="order-subject-type" v-model="order.subjectType" value="work" :disabled="!order._canEdit" /> <label for="subject-type-work">Práce</label>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label">Hlavný CPV kód <small>*</small></label>
                  <div>
                    <main-cpv-code-select v-if="order.id" v-model="order.cpvCode" :value="order.cpvCode" :disabled="!order._canEdit"></main-cpv-code-select>
                    <label v-if="form.cpvCode.error" class="error">{{ form.cpvCode.errorMessage }}</label>
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label">Doplnkové CPV kódy</label>
                  <div>
                    <suppl-cpv-code-select v-if="order.id" v-model="order.cpvCodes" :value="order.cpvCodes" :disabled="!order._canEdit"></suppl-cpv-code-select>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="description" class="control-label">Opis predmetu zákazky <small>*</small></label>
                  <div>
                    <textarea id="description" v-model="order.description" class="form-control" :disabled="!order._canEdit"></textarea>
                    Počet zostávajúcich znakov: {{ descriptionTextRemaining }}<br />
                    <label for="description" v-if="form.description.error" class="error">{{ form.description.errorMessage }}</label>
                  </div>
                </div>

                <div class="form-group">
                  <label for="announcement" class="control-label">Hlavné miesto dodania tovaru/poskytnutia služby/vykonania stavebných prác</label>
                  <div>
                    <input id="announcement" type="text" v-model="order.mainDeliveryPlace" class="form-control" :disabled="!order._canEdit">
                    <label for="announcement" v-if="form.mainDeliveryPlace.error" class="error">{{ form.mainDeliveryPlace.errorMessage }}</label>
                  </div>
                </div>

                <div class="form-group">
                  <label for="private-note" class="control-label">Poznámka</label>
                  <div>
                    <textarea id="private-note" v-model="order.privateNote"  class="form-control" :disabled="!order._canEdit"></textarea>
                    Počet zostávajúcich znakov: {{ privateNoteTextRemaining }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">Ponuky a termíny</h4>
          </div>
          <div class="card-block">
            <div class="row mt-3">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="description" class="control-label">Podmienky účasti</label>
                  <div>
                    <textarea id="description" v-model="order.participationConditions" class="form-control" :disabled="!order._canEdit"></textarea>
                    Počet zostávajúcich znakov: {{ participationConditionsTextRemaining }}<br />
                    <label for="announcement" v-if="form.participationConditions.error" class="error">{{ form.participationConditions.errorMessage }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row align-items-center no-gutters justify-content-between">
      <div class="row no-gutters">
        <button v-if="order.state === 'active' && order._canEdit"
                @click="changeStateModal('finished')"
                class="btn btn-tenderia-blue"
        >
          <i class="icon-check"></i> Ukončiť zákazku
        </button>
        <button v-if="order.state === 'active' && order._canEdit"
                @click="changeStateModal('cancelled')"
                class="btn btn-danger"
        >
          <i class="icon-close"></i> Zrušiť zákazku
        </button>
        <div v-if="order.state === 'planned' && order._canEdit">
          <button class="btn btn-danger" @click="removeOrderModal()" href=""><i class="icon-delete"></i>Zmazať zákazku</button>
        </div>
      </div>
      <div class="row no-gutters">
        <button v-if="order.integration.eforms.enabled" class="btn btn-success" @click="openEformsModal">Vyplniť v eForms</button>
        <div v-if="order._canEdit">
          <button class="btn btn-success" @click="saveSettings()" href=""><i class="icon-save"></i>Uložiť</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminOrder from '@/components/order/common/components/AdminOrder'
import DateInput from '@/components/forms/DateInput.vue'
import MainCpvCodeSelect from '@/components/forms/MainCpvCodeSelect.vue'
import SupplCpvCodeSelect from '@/components/forms/SupplCpvCodeSelect.vue'
import VueNumeric from 'vue-numeric'

export default {
  name: 'PtkAdminSettings',
  extends: AdminOrder,
  components: { DateInput, MainCpvCodeSelect, SupplCpvCodeSelect, VueNumeric },
  inject: ['order', 'orderChanged', 'eventBus'],
  data () {
    return {}
  },
  methods: {
    validateBeforePublish () {
      this.validateEstimatedPrice()
      this.validatecpvCode()
      this.validateDescription()
      this.validateMainDeliveryPlace()
      this.validateFundingByEu()
      this.validateParticipationConditions()
      this.validateJournalAnnouncementDate()
      this.validateJournal()
      this.validateAnnouncement()
      this.validateParts()

      return this.form.estimatedPrice.valid &&
      this.form.cpvCode.valid &&
      this.form.description.valid &&
      this.form.announcement &&
      this.form.journal &&
      this.form.journalAnnouncementDate.valid &&
      this.form.mainDeliveryPlace.valid &&
      this.form.fundingByEu.valid &&
      this.form.participationConditions.valid &&
      this.form.parts.valid
    },
    saveSettings (state) {
      let orderIsValid = this.validateBeforePublish()
      if (this.order.state === 'active' && !orderIsValid) {
        return
      }
      this.ShowLoading({ title: 'Ukladám nastavenia zákazky' })
      var data = {
        order: {
          id: this.order.id,
          name: this.order.name,
          estimatedPrice: this.order.estimatedPrice.toString().replaceAll(' ', '').replaceAll(',', '.'),
          description: this.order.description,
          announcementUrl: this.order.announcementUrl,
          journalAnnouncementDate: this.order.journalAnnouncementDate,
          organization: this.order.organization.id,
          privateNote: this.order.privateNote,
          offerOpeningType: this.order.offerOpeningType,
          subjectType: this.order.subjectType,
          cpvCode: this.order.cpvCode,
          cpvCodes: this.order.cpvCodes,
          journal: this.order.journal,
          announcement: this.order.announcement,
          requiredAuthLevel: this.order.requiredAuthLevel,
          mainDeliveryPlace: this.order.mainDeliveryPlace,
          fundingByEu: this.order.fundingByEu,
          participationConditions: this.order.participationConditions
        }
      }
      var vm = this
      this.$http.post('/order/set', data).then(function () {
        vm.eventBus.$emit('order-updated')
        vm.CloseLoading()
        if (state === 'active') {
          vm.changeState(state)
        } else {
          setTimeout(function () {
            vm.PopupAlert({ text: 'Zmeny boli uložené.' })
          }, 500)
        }
      }).catch((error) => {
        vm.CloseLoading()
        setTimeout(function () {
          vm.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error?.response?.data.error)
        }, 500)
      })
    }
  }
}
</script>
