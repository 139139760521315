import Vue from 'vue'
import moment from 'moment'

Vue.filter('formatLogsCategory', function (value) {
  let logsCategory = {
    'auth': 'Autentifikácia',
    'order': 'Zákazka - všeobecné',
    'order:parts': 'Zákazka - časti',
    'order:documents': 'Zákazka - dokumenty',
    'order:communication': 'Zákazka - komunikácia',
    'order:criteria': 'Zákazka - kritériá',
    /* 'order:commision': 'Zákazka - komisia', */
    'offer': 'Zákazka - ponuky',
    'order:items': 'Zákazka - položky',
    'order:persons': 'Zákazka - ľudia',
    'order:suppliers': 'Zákazka - dodávatelia'
  }
  return logsCategory[value]
})
Vue.filter('formatLogsActions', function (value, row) {
  let logsActions = {
    'order': {
      'create': 'Založenie zákazky',
      'set': 'Zmena nastavení',
      'state': 'Zmena stavu zákazky'
      /* 'start': 'Spustenie zákazky',
      'stop': 'Ukončenie zákazky',
      'cancel': 'Zrušenie zákazky',
      'remove': 'Zmazanie zákazky', */
    },
    'order:parts': {
      'create': 'Pridanie časti č.',
      'start': 'Spustenie výzvy',
      'set': 'Zmena nastavení časti',
      'remove': 'Zmazanie časti'
    },
    'order:documents': {
      'create': 'Pridanie dokumentu',
      'remove': 'Zmazanie dokumentu'
    },
    'order:communication': {
      'send': 'Správa '
    },
    'order:criteria': {
      'create': 'Pridanie kritéria',
      'set': 'Zmena nastavení kritéria',
      'remove': 'Zmazanie kritéria'
    },
    'order:commision': {
      'create': 'Pridanie člena komisie',
      'recall': 'Odvolanie člena komisie',
      'edit': 'Zmena člena komisie'
    },
    'offer': {
      'submit': 'Predloženie ponuky',
      'revoke': 'Odvolanie ponuky',
      'open': 'Otvorenie ponuky',
      'exclude': 'Vylúčenie ponuky',
      'remove': 'Odstránenie ponuky'
    },
    'order:items': {
      'create': 'Pridanie položky',
      'set': 'Zmena položky',
      'remove': 'Zmazanie položky'
    },
    'order:persons': {
      'create': 'Pridanie osoby',
      'set': 'Zmena údajov osoby',
      'remove': 'Zmazanie osoby'
    },
    'order:suppliers': {
      'set': 'Pridanie dodávateľa',
      'remove': 'Zmazanie dodávateľa',
      'invitation': 'Odoslanie pozvánky pre dodávateľa'
    },
    'order:entities': {
      'set': 'Zmena',
      'remove': 'Zmazanie',
      'create': 'Pridanie'
    }
  }
  let string = ''
  try {
    switch (row.category) {
    case 'order':
      string = ''
      break
    case 'order:parts':
      string = `${row.data.order.part} - ${row.data.order.name}`
      break
    case 'order:documents':
      if (row.action === 'remove') {
        string = row.data.removed[0].name
      } else {
        string = row.data.attachments[0].name
      }
      break
    case 'order:communication':
    {
      let to = ''
      if (row.data.request.to.length !== 0) {
        to = `${row.data.request.to[0].firstname} ${row.data.request.to[0].lastname} <${row.data.request.to[0].email}>`
      } else {
        row.data.request.bcc.forEach(recipient => {
          to += `${recipient.firstname} ${recipient.lastname} <${recipient.email}>, `
        })
        to = to.substring(0, to.length - 2)
      }
      string = `${row.data.subject} od ${row.data.request.from.firstname} ${row.data.request.from.lastname} <${row.data.request.from.email}> pre ${to}`
      break
    }
    case 'order:criteria':
      string = row.data.criterion.name
      break
    case 'order:commision':
    {let member = ''
      if (row.action === 'create') {
        member = row.data.member
      } else {
        member = row.data.order.commission.find(member => member._id === row.data.request.memberId)
      }
      string = `${member.title1} ${member.firstname} ${member.lastname} ${member.title2}`.trim()
      break}
    case 'offer':
      if (row.status === '200') {
        string = row.data.offer.applicantDetails.groupName || row.data.offer.applicantDetails.businessName
      } else {
        string = row.data.request.offer.applicantDetails.groupName || row.data.request.offer.applicantDetails.businessName
      }
      // string = row.data.offer.applicantDetails.groupName || row.data.offer.applicantDetails.businessName
      break
    case 'order:items':
      string = row.data.item.name
      break
    case 'order:persons':
      string = ''
      break
    case 'order:suppliers':
      string = row.action === 'remove' ? row.data.removed[0].name : row.data.request.supplier.name
      break
    case 'order:entities':
    {
      let entity = row.action === 'remove' ? row.data.entity : row.data.relatedEntity
      if (entity.type === 'person') {
        string = 'záujmovej osoby - '
        string += `${entity.title1} ${entity.firstname} ${entity.lastname} ${entity.title2}`.trim()
      } else {
        string = `záujmovej organizácie - ${entity.businessName} IČO: ${entity.cin}`
      }
      break
    }
    default:
      string = ''
    }
  } catch (error) {
    string = ''
  }
  return string ? `${logsActions[row.category][value]} ${string}` : logsActions[row.category][value]
}
)

var filesize = require('filesize')
Vue.filter('formatFileSize', function (value) {
  return filesize(value)
})

var db = require('mime-db')
var mime = require('mime-type')(db)
Vue.filter('formatFileType', function (value) {
  return mime.extension(value)
})

var formatNumber = require('format-number')
var priceFormat = formatNumber({ integerSeparator: ' ', decimalsSeparator: ' ', decimal: ',' })
Vue.filter('formatPrice', function (value) {
  return priceFormat(value)
})

Vue.filter('formatDate', function (value) {
  return value ? moment(value).format('DD. MM. YYYY') : ''
})

Vue.filter('formatDateTime', function (value) {
  return value ? moment(value).format('DD. MM. YYYY HH:mm') : ''
})

Vue.filter('formatTime', function (value) {
  return value ? moment(value).format('HH:mm:ss') : ''
})

Vue.filter('formatYesNo', function (value) {
  return value === 'yes' ? 'Áno' : 'Nie'
})

Vue.filter('formatOfferState', function (value) {
  let stages = {
    new: 'Neodoslaná',
    sent: 'Odoslaná',
    seen: 'Otvorená',
    excluded: 'Vylúčená'
  }
  return stages[value]
})

Vue.filter('formatOrderSubject', function (value) {
  let subjects = {
    goods: 'Tovary',
    services: 'Služby',
    work: 'Práce'
  }
  return subjects[value]
})

Vue.filter('formatOfferOpeningType', function (value) {
  let types = {
    nonpublic: 'Neverejné',
    personal: 'Fyzické',
    distant: 'Dištančné'
  }
  return types[value]
})

Vue.filter('formatLegalBasis', function (value) {
  let types = {
    'vlo': 'Mimoriadne nízka ponuka',
    'p40zvo': '&#167; 40 ods. 6 ZVO',
    'p53zvo': '&#167; 53 ZVO'
  }
  return types[value]
})

Vue.filter('formatPartsBidding', function (value) {
  let types = {
    one: 'jednu časť',
    several: 'niekoľko častí',
    all: 'všetky časti'
  }
  return types[value]
})

Vue.filter('formatProcedureType', function (order) {
  let procedureType = {
    'nh-nh': 'Prieskum trhu',
    'pz-vs': 'Podlimitná zákazka',
    'nz-vs': 'Nadlimitná zákazka - verejná súťaž',
    'dns-dns': 'Dynamický nákupný systém',
    'ptk-ptk': 'Predbežné trhové konzultácie',
    'nz-us': 'Nadlimitná zákazka - užšia súťaž',
    'nk-us': 'Nadlimitná koncesia - užšia súťaž',
    'ob-us': 'Zákazka v oblasti obrany a bezpečnosti - užšia súťaž',
    'nz-sd': 'Nadlimitná zákazka - súťažný dialóg',
    'nk-sd': 'Nadlimitná koncesia - súťažný dialóg',
    'nk-kd': 'Nadlimitná koncesia - koncesný dialóg',
    'ob-kd': 'Zákazka v oblasti obrany a bezpečnosti - koncesný dialóg',
    'nz-rkz': 'Nadlimitná zákazka - rokovacie konanie so zverejnením',
    'nk-rkz': 'Nadlimitná koncesia - rokovacie konanie so zverejnením',
    'ob-rkz': 'Zákazka v oblasti obrany a bezpečnosti - rokovacie konanie so zverejnením',
    'nz-prk': 'Priame rokovacie konanie',
    'ob-prk': 'Zákazka v oblasti obrany a bezpečnosti - priame rokovacie konanie',
    'nz-ip': 'Nadlimitná zákazka - inovatívne partnerstvo',
    'nk-ovs': 'Nadlimitná koncesia - obchodná verejná súťaž',
    'sn-sn': 'Súťaž návrhov',
    'pk-pk': 'Podlimitná koncesia',
    'nk-vs': 'Nadlimitná koncesia - verejná súťaž'
  }
  return procedureType[`${order.type}-${order.subtype}`]
})

Vue.filter('formatMessageType', function (value) {
  let tenderiaMessageTypes = {
    'nam': 'Námietka',
    'zvpd': 'Žiadosť o vysvetlenie alebo doplnenie predložených dokladov',
    'zvp': 'Žiadosť o vysvetlenie ponuky',
    'zvnp': 'Žiadosť o vysvetlenie mimoriadne nízkej ponuky',
    'zpde': 'Žiadosť o predloženie dokladov nahradených Jednotným európskym dokumentom',
    'vsp': 'Vysvetlenie súťažných podkladov',
    'znio': 'Žiadosť o nahradenie inej osoby',
    'zns': 'Žiadosť o nahradenie subdodávateľa',
    'iop': 'Informácia o otváraní ponúk',
    'ovu': 'Oznámenie o vylúčení uchádzača',
    'ovp': 'Oznámenie o vylúčení ponuky',
    'zop': 'Zápisnica z otvárania ponúk',
    'ovhp': 'Oznámenie o vyhodnotení ponúk',
    'id': 'Iný dokument',
    'zvsp': 'Žiadosť o vysvetlenie súťažných podkladov',
    'ozvp': 'Odpoveď na žiadosť o vysvetlenie ponuky',
    'ozvpd': 'Odpoveď na žiadosť o vysvetlenie alebo doplnenie predložených dokladov',
    'ozvnp': 'Odpoveď na  žiadosť o vysvetlenie mimoriadne nízkej ponuky',
    'ozpde': 'Odpoveď na žiadosť o predloženie dokladov nahradených Jednotným európskym dokumentom',
    'oznio': 'Odpoveď na žiadosť o nahradenie inej osoby',
    'ozns': 'Odpoveď na žiadosť o nahradenie subdodávateľa',
    'zon': 'Žiadosť o nápravu',
    'pzzz': 'Oznámenie o zaradení medzi záujemcov',
    'zu': 'Identifikácia známeho záujemcu',
    'np': 'Nová ponuka',
    'op': 'Odvolanie ponuky',
    'pnp': 'Potvrdenie o odoslaní ponuky',
    'sys': 'Systémová správa',
    'sys-alt': 'Systémová správa',
    'dns-vyz': 'Výzva na predkladanie ponúk',
    'wbr': 'Školenia'
  }
  return tenderiaMessageTypes[value]
})

Vue.filter('formatDocumentType', function (value) {
  let tenderiaDocumtnTypes = {
    sp: 'Súťažné podklady',
    vsp: 'Vysvetlenie súťažných podkladov',
    zm: 'Zmluva',
    izm: 'Informácia o zverejnení zmluvy',
    iop: 'Informácia o otváraní ponúk',
    ivvp: 'Informácia o výsledku vyhodnotenia ponúk',
    onpp: 'Odôvodnenie nezrušenia použitého postupu',
    pu: 'Ponuky uchádzačov',
    sz: 'Správa o zákazke',
    ssup: 'Suma skutočne uhradeného plnenia',
    zop: 'Zápisnica z otvárania ponúk',
    zvsu: 'Zápisnica z vyhodnotenia splnenia podmienok účasti',
    zvp: 'Zápisnica z vyhodnotenia ponúk',
    zs: 'Zoznam subdodávateľov',
    id: 'Iný dokument k zákazke'
  }
  return tenderiaDocumtnTypes[value]
})

Vue.filter('formatLegalForm', function (value) {
  let legalForms = {
    sp: 'štátny podnik',
    as: 'akciová spoločnosť',
    es: 'európska spoločnosť',
    sro: 'spoločnosť s ručením obmedzeným',
    vos: 'verejná obchodná spoločnosť',
    ks: 'komanditná spoločnosť',
    spfo: 'samostatne podnikajúca fyz. osoba',
    d: 'družstvo',
    jsna: 'jednoduchá spoločnosť na akcie',
    ed: 'európske družstvo',
    ezhz: 'európske zoskupenie hosp. záujmov',
    ozp: 'organizačná zložka podniku',
    ozzo: 'organizačná zložka zahran. osoby',
    op: 'obecný podnik',
    ou: 'obecný úrad',
    eiamo: 'európska inštitúcia/agentúra alebo medzinárodná organizácia',
    mso: 'ministerstvo alebo iný štátny orgán vrátane jeho regionálnych alebo miestnych útvarov',
    orvp: 'organizácia riadená verejným právom',
    rmau: 'regionálna alebo miestna agentúra/úrad',
    rmo: 'regionálny alebo miestny orgán',
    sau: 'štátna agentúra/úrad',
    in: 'iná'
  }
  return legalForms[value]
})
Vue.filter('formatDepartmentType', function (value) {
  let departmentTypes = {
    'department': 'Oddelenie',
    'division': 'Odbor',
    'unit': 'Úsek',
    'section': 'Referát'
  }
  return departmentTypes[value]
})
